:root{
  --card-border-color: var(--gray-5);
}

[dir='ltr'] {
  --text-x-direction: 1;
}

[dir='rtl'] {
  --text-x-direction: -1;
}

body {
  -webkit-overflow-scrolling: touch;
  background-color: var(--gray-9);
}

ul {
  padding-inline-start: 0;
}

b, strong {
  font-weight: bold;
}

.ant-btn-link {
  padding: 0;
  height: auto;
  line-height: 1;
  font-weight: inherit;
}

/* Hide empty menu items */
.ant-menu-item:empty {
  display: none;
}

.ant-dropdown-menu-item:empty {
  display: none;
}

.ant-layout {
  background: none;
}

.ant-btn > .svg-inline--fa + span,
.ant-btn > span + .svg-inline--fa {
  margin-left: 8px;
}

.ant-switch-small .ant-switch-inner {
  margin-right: 4px;
}

.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin-left: 4px;
}

.loading-container {
  width: 100%;
  margin-top: 200px;
  text-align: center;
  color: #ccc;
}

.anticon > .svg-inline--fa {
  vertical-align: 0;
}

.cookie-banner-wrapper {
  z-index: 1000;
  position: absolute;
}

.red-pulse {
  box-shadow: 0 0 0 rgba(255, 0, 25, 0.4);
  animation: pulse 2s infinite;
}

.ant-popover-message-title {
  max-width: 320px;
}

/* patch fix until upgrade to antd v4 (user agent override) */
.ant-alert-close-icon {
  padding: 0;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 25, 0.4);
  }
  70% {
    box-shadow: 0 0 0 5px rgba(255, 0, 25, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 25, 0);
  }
}

@media (min-width: 769px) {
  body {
    overflow: hidden;
  }
}

@media (min-width: 1800px) {
  body {
    font-size: 18px;
  }
}

.grey-link-btn {
  color: rgba(0, 0, 0, .65);
  border: none;
}

.hidden {
  display: none;
}

.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon.danger,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon.danger {
  color: var(--antd-danger);
}


.ant-card-bordered{
  border: 1px solid var(--card-border-color);
}
.ant-card-bordered > .ant-card-head{
  border-bottom: 1px solid var(--card-border-color);
}

.ant-card-body.ant-page-header-heading-title,
.ant-card-head .ant-page-header-heading-title {
  margin-right: 0;
}

.acsb-trigger.acsb-ready .acsb-trigger-icon svg path, .acsb-trigger.acsb-ready .acsb-trigger-icon svg circle {
  fill: var(--gray-8) !important;
}

div.acsb-trigger.acsb-widget.acsb-ready {
  display: none !important;
}

.acsb-trigger.acsb-ready .acsb-actions-active-icon {
  background-color: var(--gray-10) !important;
}

:dir(rtl) access-widget-ui::part(acsb-trigger) {
  left: var(--offset-left);
  right: auto;
}

iframe#launcher {
    display: none;
}

.ant-table-thead > tr > th {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: start;
}

.ant-table table {
  text-align: start;
}

.grecaptcha-badge {
  visibility: hidden;
}

@media (max-width: 560px) {
  .ant-picker-panel-container .ant-picker-panels {
    display: block;
  }

  .ant-picker-panel {
    display: block;
  }

}

.customEmbed{
  color: var(--magenta-7)
}

.float-end {
  float: inline-end;
}

